import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import * as Sentry from "@sentry/react";
import "./index.css";
import "./i18n";
import { LicenseInfo } from "@mui/x-license";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

// MUI license activation
LicenseInfo.setLicenseKey(
  "5a088a287f647ada344f8f286ff01e2eTz04OTYxNyxFPTE3NDYyNzkxNjgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

if (import.meta.env.MODE === "production") {
  // Log Rocket initialization
  LogRocket.init("xbdpwh/agilo");

  setupLogRocketReact(LogRocket);

  // Sentry initialization
  Sentry.init({
    //dsn: "https://f89db90b756f74066b745ad0bc78cc5d@o98173.ingest.us.sentry.io/4508365745881088",
    dsn: "https://53e0133f15c4e3ba3df2e2c16058fc65@o4507166447370240.ingest.us.sentry.io/4507166448549888",
    enabled: true,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
      }),
    ],
    environment: import.meta.env.MODE,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
}

// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}
